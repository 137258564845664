import css from 'styled-jsx/css';

import React, {
  RefObject,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEventHandler,
  ChangeEvent,
} from 'react';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import { useTracker } from '~/app/lib/tracker/useTracker';
import useDebounce from '~/app/lib/hooks/useDebounce';
import Clickable from '~/app/components/Clickable';
import useTheme from '~/app/lib/hooks/useTheme';
import CrossIcon from '../Icon/CrossIcon';
import Box from '~/app/components/Box';

const styles = css.resolve`
  .root {
    position: relative;
    align-items: center;
  }

  .root:focus-within {
    border-color: #555 !important;
  }

  .root.invalid {
    border-color: #812b2b !important;
    background: rgba(121, 42, 42, 0.38) !important;
  }

  .input {
    position: relative;
    height: 100%;
    flex: 1;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    padding: 0;
    background: none;
    border-radius: 0;
    border: 0;
    width: 100%;
    height: 100%;
    color: inherit;
    font-family: inherit;
    font-weight: 300;
    letter-spacing: 0.02em;
  }

  input::placeholder {
    color: inherit;
    opacity: 0.4;
    letter-spacing: 0.03em;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  /* https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
    font: inherit;
  }
`;

export const TEXT_INPUT_HORIZONTAL_PADDING = '0.75em';
export const TEXT_INPUT_DEFAULT_HEIGHT = '4.8rem';

// >= 16px to avoid ios zooming in
export const TEXT_INPUT_DEFAULT_FONT_SIZE = '1.7rem';

const DEFAULT_DEBOUNCE_TIMEOUT = 600;

export type TextInputToValidationMessage = (params: {
  value: string;
}) => string | undefined;

export type TextInputOnChange = (params: {
  value: string;
  event: ChangeEvent<HTMLInputElement>;
  el: HTMLInputElement;
  setValue: (nextValue: string) => void;
}) => void;

export type TextInputOnInputEnd = (params: {
  value: string;
  event: ChangeEvent<HTMLInputElement>;
  isValid: boolean;
}) => void;

export interface TextInputProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      'onChange' | 'size' | 'pattern'
    >,
    WithSpacingProps {
  borderColor?: string;
  debounceTimeout?: number;
  value?: string;
  defaultValue?: string;
  fontSize?: number | string;
  type?: 'text' | 'email' | 'url';
  renderBefore?: () => JSX.Element | null;
  renderAfter?: (params: {
    clear: () => void;
    background: string;
  }) => JSX.Element | undefined | void;
  centerText?: boolean;
  withBorder?: boolean;
  withBackground?: boolean;
  withShadow?: boolean;
  isDisabled?: boolean;
  withTracking?: boolean;
  showClearButtonOnEmpty?: boolean;
  testId?: string;

  /**
   * Cleans leading and excess whitespace as the user types.
   *
   * @default true
   */
  withCleanOnChange?: boolean;

  toValidationMessage?: TextInputToValidationMessage;
  onChange?: TextInputOnChange;

  /**
   * Debounce callback, called when user finishes typing.
   *
   * WARN: make sure to memo-ize this function using `useCallback()`
   * otherwise strange bugs will happen.
   */
  onInputEnd?: TextInputOnInputEnd;

  onClear?: () => void;
  inputRef?: RefObject<HTMLInputElement>;
}

const TextInput = withSpacing<TextInputProps>(
  ({
    borderColor,
    onClear,
    onChange,
    onInputEnd,
    placeholder,
    type = 'text',
    renderBefore,
    renderAfter,
    centerText,
    withBorder = true,
    withBackground = true,
    withShadow = true,
    testId,
    inputRef,
    className = '',
    isDisabled,
    fontSize = TEXT_INPUT_DEFAULT_FONT_SIZE,
    toValidationMessage,
    withCleanOnChange = true,
    showClearButtonOnEmpty = false,
    style,
    autoComplete = 'off',
    debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
    withTracking = false,
    autoFocus,
    ...inputProps
  }) => {
    const theme = useTheme();
    const height = style?.height || TEXT_INPUT_DEFAULT_HEIGHT;
    const defaultRef = useRef<HTMLInputElement>(null);
    const [isInvalid, setIsInvalid] = useState(false);
    const { trackEvent } = useTracker();

    inputRef = inputRef || defaultRef;

    useEffect(() => {
      if (autoFocus) {
        const el = inputRef?.current;

        if (el) {
          el.focus();
          const valueLength = el.value.length;
          el.setSelectionRange(valueLength, valueLength);
        }
      }
    }, []);

    const clear = () => {
      const el = inputRef?.current;

      if (el) {
        el.value = '';
        el.focus();
      }
    };

    const renderClearButton = (showOnEmpty: boolean) => {
      const hasQuery = !!inputProps.value;
      const opacity = hasQuery || showOnEmpty ? '' : 0;

      return (
        <Clickable
          onClick={onClear}
          width="auto"
          margin="0 .5em"
          testId={`${testId}-clearButton`}
        >
          <CrossIcon style={{ opacity }} />
        </Clickable>
      );
    };

    // REVIEW: it's kinda annoying UX how fields are validated on keypress.
    // For example you get shouted out when you've entered partial data
    // that doesn't match a validation pattern (eg. email or url). It would
    // we better to show invalid state on <form> submit, maybe one field at a time.
    // In order to do this we could set the validation message onChange, but only
    // .reportValidity() on submit. We'd need to revise how we show the red input
    // background, perhaps an `input:invalid:before`  selector could work with an
    // absolutely positioned div that sits under the content?
    const onChangeDebounced = useDebounce<TextInputOnChange>(
      ({ value, event, el }) => {
        if (toValidationMessage) {
          const message = toValidationMessage({ value }) || '';

          // setting a custom validation message causes el.checkValidity() to return false
          el.setCustomValidity(message);
        }

        // If the field is `required` and empty or a `pattern` doesn't match
        // the value this will return `false` and the state change will turn the field red.
        const isValid = !value || !!el.reportValidity();

        setIsInvalid(!isValid);

        if (withTracking) {
          trackEvent({
            type: 'input-text',
            value,
            className: el.className,
            id: el.id || testId,
          });
        }

        if (onInputEnd) {
          onInputEnd({
            value,
            event,
            isValid,
          });
        }
      },
      debounceTimeout,
      [onInputEnd, toValidationMessage]
    );

    const onChangeInternal = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        const el = event.target;

        const setValue = (nextValue: string) => {
          if (nextValue !== el.value) {
            const delta = nextValue.length - el.value.length;
            const cursorPosition = el.selectionStart;

            el.value = nextValue;

            // after value changed ensure caret position is preserved
            if (typeof cursorPosition === 'number') {
              const nextCaretPosition = cursorPosition + delta;
              el.setSelectionRange(nextCaretPosition, nextCaretPosition);
            }
          }
        };

        // trim leading whitespace and excess spaces
        if (withCleanOnChange) {
          const cleaned = el.value.trimStart().replace(/ +/g, ' ');
          setValue(cleaned);
        }

        if (onChange) {
          onChange({
            setValue,
            value: el.value,
            event,
            el,
          });
        }

        // don't show invalid styling while typing
        setIsInvalid(false);

        // hide validation messages while the user is typing
        if ((toValidationMessage && isInvalid) || el.validationMessage) {
          el.setCustomValidity('');
        }

        onChangeDebounced({
          setValue,
          value: el.value,
          event,
          el,
        });
      },
      [onChange, isInvalid, onChangeDebounced]
    );

    const background = withBackground ? theme.textInputBackground : '#000';

    const inputStyle: React.CSSProperties = {
      textAlign: centerText ? 'center' : 'left',
      fontSize: '1em',
      color: theme.textColor90,
      scrollPadding: '1em',
      paddingRight: centerText ? '' : `0 ${TEXT_INPUT_HORIZONTAL_PADDING}`,
      textIndent: centerText ? '' : TEXT_INPUT_HORIZONTAL_PADDING,
    };

    if (isDisabled) inputStyle.opacity = 0.4;
    if (isInvalid) className += ' invalid';

    const beforeContent = renderBefore && renderBefore();

    return (
      <Box
        flexRow
        className={`${styles.className} ${className} root`}
        style={{
          background,
          border: withBorder
            ? `solid 1px ${borderColor || theme.textInputBorderColor}`
            : 'none',
          borderRadius: withBorder ? `${theme.borderRadius}px` : undefined,
          boxShadow: withShadow ? '0 1px 3px rgba(0,0,0,1)' : undefined,
          overflow: 'hidden',
          contain: 'strict',
          fontSize,
          height,
          ...style,
        }}
      >
        {beforeContent}
        <div className={`${styles.className} input`}>
          <input
            {...inputProps}
            ref={inputRef}
            type={type}
            className={styles.className}
            placeholder={placeholder}
            onChange={onChangeInternal}
            autoComplete={autoComplete}
            autoCorrect="off"
            autoCapitalize="off"
            autoFocus={autoFocus}
            spellCheck="false"
            data-testid={testId}
            style={inputStyle}
            disabled={isDisabled}
          />
        </div>
        {(() => {
          let content;

          if (renderAfter) content = renderAfter({ clear, background });
          else if (onClear) return renderClearButton(showClearButtonOnEmpty);

          return content;
        })()}
        {styles.styles}
      </Box>
    );
  }
);

export default TextInput;
